.login-bg {
  height  : 100vh;
  display : block;
  position: relative;
  padding : 70px 0 0 0;

  &::before {
    content        : '';
    position       : absolute;
    left           : 0;
    top            : 0;
    right          : 0;
    height         : 371px;
    background     : #7659ff url(../../images/login-bg.png) center -400px no-repeat;
    background-size: 100% auto;
  }

  .logo {
    display        : flex;
    flex-wrap      : wrap;
    justify-content: center;
    margin         : 0 0 40px;

    h3 {
      width      : 100%;
      display    : block;
      font-size  : 20px;
      font-weight: bold;
      color      : #fff;
      text-align : center;
      margin     : 10px 0 0 0;
    }
  }

  form {
    display         : block;
    border-radius   : 6px;
    box-shadow      : 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding         : 30px;
  }

  h2 {
    font-size     : 30px;
    text-align    : center;
    color         : #6145e3;
    display       : block;
    margin        : 0 0 20px;
    line-height   : 1;
    font-weight   : bold;
    text-transform: uppercase;
  }


  .form-group {
    display: block;

    lable {
      display    : block;
      width      : 100%;
      line-height: 1;
      font-size  : 14px;
      font-weight: 500;
      color      : #999;
      margin     : 0 0 5px;
    }

    .form-control {
      height          : auto;
      padding         : 12px 20px;
      border-radius   : 6px;
      border          : solid 1px #d8d8d8;
      background-color: #ffffff;
      font-size       : 13px;
      color           : #999;
      margin          : 0;
      line-height     : 1;
    }

    span {
      font-size: 11px;
      display  : block;
      width    : 100%;
      color    : #f00;
      margin   : 5px 0 0 0;
    }

  }

  .text-forgot {
    display        : flex;
    flex-wrap      : wrap;
    justify-content: flex-end;
    margin         : 0 0 30px;

    a {
      font-size      : 14px;
      color          : #666;
      font-weight    : 500;
      text-decoration: none;
    }

  }

  .submit_btn {
    display   : block;
    text-align: center;

    .btn.btn-primary {
      display      : inline-block;
      line-height  : 1;
      background   : #666;
      border-radius: 6px;
      border       : 0;
      padding      : 14px 47px;
      font-size    : 14px;
      color        : #fff;
      font-weight  : 500;
    }
  }
}
