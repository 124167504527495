// Form control placeholder css

.form-group .form-control::-webkit-input-placeholder {
  //  Edge
  color: #a0a0a0;
}

.form-group .form-control:-ms-input-placeholder {
  // Internet Explorer 10-11
  color: #a0a0a0;
}

.form-group .form-control::placeholder {
  color: #a0a0a0;
}


.form-group {
  display: block;

  lable {
    display    : block;
    width      : 100%;
    line-height: 1;
    font-size  : 16px;
    color      : #212529;
    margin     : 0 0 5px;
  }

  .form-control {
    height          : auto;
    padding         : 7.5px 15px;
    border-radius   : 6px;
    border          : solid 1px #d8d8d8;
    background-color: #ffffff;
    font-size       : 16px;
    color           : #495057;
    margin          : 0;
    line-height     : 1;
    height          : calc(1.5em + .75rem + 4px);
  }




  span {
    font-size: 14px;
    display  : block;
    width    : 100%;
    color    : #f00;
    margin   : 5px 0 0 0;
  }

  span.input-group-text {
    margin: 0 !important;
    color : inherit !important;
  }

  .text-gray {
    color: red
  }

}

.updatePage {
  .handlepageCheckbox {
    display        : flex;
    align-items    : center;
    justify-content: flex-start;
    width          : 70px;

    lable {
      margin: 0 !important;
    }
  }

  .loading {
    &::before {
      content           : "Fetcing Form Data...";
      background        : #7659ff1f;
      width             : 100%;
      height            : 100%;
      top               : 0px;
      left              : 0;
      // backdrop-filter: blur(5px);
      text-align        : center;
      line-height       : 8;
      border-radius     : 6px;
      font-size         : 25px;
      z-index           : 999999999;
      position          : absolute;
    }
  }

  .checkbox {
    position         : relative;
    display          : inline-block;
    // display       : block;
    cursor           : pointer;
    margin-top       : 2px;
    margin-left      : 10px;

    input {
      position: absolute;
      left    : 0;
      top     : 0;
      margin  : 0;
      opacity : 0;
      padding : 0;
      cursor  : pointer;
      height  : 100%;
      width   : 100%;

      +label {
        // margin-bottom   : 10px;
        margin-left     : 10px;
        width           : 20px;
        height          : 20px;
        border          : solid 1px #D8D8D8;
        background-color: #FFFFFF;
        margin          : 0;
        padding         : 0;
        border-radius   : 2px;
        box-shadow      : 0 2px 2px rgba(0, 0, 0, 0.16);
      }

      &:checked {

        +label {
          background: #fff url(../../../images/checked.png) center center no-repeat;
        }

      }

    }

  }
}

.stepwizard {
  display: block;

  .stepwizard-row {
    display  : flex;
    flex-wrap: wrap;
    margin   : 0 0 20px;

    .stepwizard-step {
      display        : flex;
      flex-wrap      : wrap;
      justify-content: center;
      width          : 16.66667%;
      position       : relative;

      &:before {
        content         : '';
        position        : absolute;
        right           : -50%;
        top             : 18px;
        width           : 100%;
        height          : 1px;
        background-color: #e3e3e3;
      }

      &:last-child {

        &::before {
          display: none;
        }

      }

      button {
        display         : flex;
        flex-wrap       : wrap;
        justify-content : center;
        align-items     : center;
        width           : 35px;
        height          : 35px;
        border          : solid 1px #e3e3e3;
        border-radius   : 50%;
        line-height     : 1;
        background-color: #e3e3e3;
        position        : relative;
        z-index         : 1;
        opacity         : 1;

        &.btn-success {
          background-color: #7659ff;
          border-color    : #7659ff;
          box-shadow      : inset 0 0 0 3px #fff;
        }

      }

      p {
        width      : 100%;
        display    : block;
        margin     : 10px 0 0 0;
        text-align : center;
        font-size  : 16px;
        line-height: 1;
      }

    }

  }

}


select option {
  color: #495057 !important;
}