.main {

	.layout {
		width      : calc(100% - 280px);
		margin-left: auto;
		padding    : 0 60px;

		@media only screen and (max-width: 1600px) {
			padding: 0 30px;
		}

	}

	&.open {
		transition: all 0.5s;

		nav.sliderbar {
			width     : 100px;
			transition: all 0.5s;
			top       : 10px;

			.navbar-toggler {
				width  : 90px;
				height : 83px;
				padding: 18px;

				.navbar-toggler-icon {
					background: url(./images/arrow-left.png) center center no-repeat;
				}

			}

			.navbar-brand {
				padding: 75px 0 20px;

				h3 {
					display: none;
				}

			}

			.navbar-collapse {
				height: calc(100vh - 154px);

				ul {
					padding: 0 20px;

					li {

						a.nav-link {
							width        : 60px;
							height       : 60px;
							border-radius: 30px;

							i {
								margin    : 0;
								font-size : 18px;
								display   : block;
								text-align: center;
							}

							span {
								display: none;
							}

						}

					}

				}

			}

		}

		.layout {
			width     : calc(100% - 100px);
			transition: all 0.5s;
		}

	}

}

.calendar-group {
	label {
		display: block;
	}

	.react-datepicker-wrapper {
		width: 100%;
	}
}


.react-datepicker-wrapper input.form-control {
	width     : 125px;
	text-align: center;
	;
}

.greenBG {
	.react-datepicker-wrapper .form-control {
		background-color: #e5fbe5 !important;
	}
}

.redBG {
	.react-datepicker-wrapper .form-control {
		background-color: #ffe5e5 !important;
	}
}

.disabled-text {
	opacity: 0.7;
	cursor : not-allowed;
}

.read-only {
	background-color: #dddddd80 !important;
	cursor          : not-allowed !important;
}

.closeBtn {
	border    : 0;
	background: transparent;
	color     : rgba(0, 0, 0, 0.35);
	outline   : none;
	cursor    : pointer;

	&:hover {
		// color     : #6145e3;
		opacity   : 0.7;
		transition: ease-in-out .5s;
	}
}


.remove-btn {
	margin-right: 0.5rem;
}

.item-enter {
	opacity: 0;

}

.item-enter-active {
	opacity   : 1;
	transition: opacity 300ms ease-in;
	display   : flex;
	width     : 100%;
}

.item-exit {
	opacity: 1;

}

.item-exit-active {
	opacity   : 0;
	transition: opacity 200ms ease-in;

}

// .item-enter-done {}


.todo-list>div {
	display    : flex;
	width      : 100%;
	align-items: flex-end;
}


.outlet .dropdown-menu.show.dropdown-menu-right {
	width    : 50px;
	transform: translate(-50px, 38px) !important;
	min-width: 155px !important;
}

.main-content button#dropdown-basic {
	padding: 0 !important;
}

.active-from-to-date {
	.date-wrapper {
		flex: 0 0 20%
	}
}


.min-height {
	min-height: 22px;
}

.accordion-card button {
	border    : 0;
	background: transparent;
	width     : 100%;
	text-align: left;
}