@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap");

html,
body {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

body.body-main {
  background: #f8f7fc url(./images/body-dark-background.png) top right no-repeat;
  background-size: 100% auto;
}

#root {
  min-height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

p {
  color: #212529;
}

@font-face {
  font-family: "icomoon";
  src: url("./assets/fonts/icomoon.eot?2pvn9h");
  src: url("./assets/fonts/icomoon.eot?2pvn9h#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/icomoon.ttf?2pvn9h") format("truetype"),
    url("./assets/fonts/icomoon.woff?2pvn9h") format("woff"),
    url("./assets/fonts/icomoon.svg?2pvn9h#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add-extensions:before {
  content: "\e900";
}

.icon-ad-groups:before {
  content: "\e901";
}

.icon-audiences:before {
  content: "\e902";
}

.icon-billing:before {
  content: "\e903";
}

.icon-bulk-action:before {
  content: "\e904";
}

.icon-calendar:before {
  content: "\e905";
}

.icon-clock:before {
  content: "\e906";
}

.icon-close:before {
  content: "\e907";
}

.icon-compaigns:before {
  content: "\e908";
}

.icon-demographics:before {
  content: "\e909";
}

.icon-dislike:before {
  content: "\e90a";
}

.icon-down-arrow:before {
  content: "\e90b";
}

.icon-expand:before {
  content: "\e90c";
}

.icon-humber-arrow:before {
  content: "\e90d";
}

.icon-humber-menu:before {
  content: "\e90e";
}

.icon-keyword:before {
  content: "\e90f";
}

.icon-lamp .path1:before {
  content: "\e910";
  color: rgb(255, 209, 92);
}

.icon-lamp .path2:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-lamp .path3:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(52, 74, 94);
}

.icon-lamp .path4:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(52, 74, 94);
}

.icon-lamp .path5:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(65, 90, 107);
}

.icon-lamp .path6:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(65, 90, 107);
}

.icon-lamp .path7:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(255, 209, 92);
}

.icon-lamp .path8:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(255, 209, 92);
}

.icon-lamp .path9:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(255, 209, 92);
}

.icon-lamp .path10:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(255, 209, 92);
}

.icon-lamp .path11:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(255, 209, 92);
}

.icon-lamp .path12:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(255, 209, 92);
}

.icon-lamp .path13:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(255, 209, 92);
}

.icon-landing-pages:before {
  content: "\e91d";
}

.icon-left-nevigation:before {
  content: "\e91e";
}

.icon-measurment:before {
  content: "\e91f";
}

.icon-notification:before {
  content: "\e920";
}

.icon-overview:before {
  content: "\e921";
}

.icon-placements:before {
  content: "\e922";
}

.icon-planning:before {
  content: "\e923";
}

.icon-polygon-down:before {
  content: "\e924";
}

.icon-polygon-left:before {
  content: "\e925";
}

.icon-product-groups:before {
  content: "\e926";
}

.icon-question:before {
  content: "\e927";
}

.icon-recommendations:before {
  content: "\e928";
}

.icon-report:before {
  content: "\e929";
}

.icon-right-arrow:before {
  content: "\e92a";
}

.icon-left-arrow:before {
  content: "\e9c0";
}

.icon-right-nevigation:before {
  content: "\e92b";
}

.icon-select-down-arrow:before {
  content: "\e92c";
}

.icon-settings:before {
  content: "\e92d";
}

.icon-airplane:before {
  content: "\e0aa";
}

.icon-shared-liberary:before {
  content: "\e92e";
}

.icon-tool-setting:before {
  content: "\e92f";
}

.icon-view:before {
  content: "\e930";
}

.Toastify__toast-container .Toastify__toast {
  min-height: 30px !important;
}

.Toastify__toast-container .Toastify__toast--success {
  background-color: #7659ff;
  font-size: 15px;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

header .reports-list .dropdown ul li a.active {
  color: #7659ff;
  font-weight: 600;
}

.shine {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  position: relative;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}

.overview-main .overall .card-container .loadingCard {
  display: block !important;
  width: 325px;
  height: 100px;
  margin-top: 15px;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  padding: 3px 0 !important;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.dismiss:hover,
.view:hover {
  color: #6145e3;
  cursor: pointer;
}

.detail-box .view a {
  color: inherit !important;
}

.main-content.my-notification .detail-box {
  display: flex;
  justify-content: flex-end;
}

.main-content.my-notification .alert {
  background: #ffffff;
  margin-bottom: 0;
}

.main-content.my-notification .rt-thead.-header {
  display: none;
}

.main-content.my-notification .ReactTable.striped {
  border: none !important;
}

.replenishment-box.notification .noNotificationMsg {
  padding: 5px 20px;
  box-shadow: rgb(140 140 140) 10px 7px 13px 0px;
  width: calc(100% - 18px);
}

.cardHeading {
  position: absolute;
  top: -10px;
  left: 18px;
  background-color: #fff;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}

.seasonWrapper .card {
  padding: 0.9rem 1.25rem 0.75rem 1.25rem !important;
}

.seasonWrapper .customtooltip {
  height: 30px;
  width: 30px;
  /* background     : #846aff; */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #846aff;
  cursor: pointer;
}

.tooltip-inner {
  max-width: 380px !important;
}

.main .main_child {
  display: inline-block;
  position: relative;
}

.child_wrapper {
  display: inline-block;
  position: relative;
}

.someone-else {
  color: #7659ff;
  display: inline-block;
  overflow-y: hidden;
  vertical-align: bottom;
  max-height: 1.59em;
  border-bottom: 5px solid #e2e1e5;
  margin: 0 13px;
  min-width: 180px;
  text-align: center;
  max-width: 450px;
}

.someone-else.storeSearch {
  /* width: auto; */
  width: auto !important;
}

._23wfu {
  display: inline-block;
  transform: translateY(0);
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.child_wrapper .store {
  width: 400px !important;
}

.child_wrapper ._3RLzW {
  background: #fff;
  font-weight: 400;
  height: 0;
  left: 0;
  min-width: 100%;
  overflow: hidden;
  position: absolute;
  top: 100%;
  width: auto;
  z-index: 100;

  transition: height 0.4s cubic-bezier(0.23, 1, 0.32, 1),
    box-shadow 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  font-weight: 400;
  box-shadow: 15px 15px 20px 0 rgb(194 199 203 / 16%),
    -15px 0 20px 0 rgb(194 199 203 / 14%);
}

.child_wrapper ._3RLzW {
  font-weight: 400;
  box-shadow: 15px 15px 20px 0 rgb(194 199 203 / 16%),
    -15px 0 20px 0 rgb(194 199 203 / 14%);
}

.main._2Vrov {
  display: inline-block;
}

.drop-down-site-owner:hover ._3RLzW {
  /* display: block !important; */
  /* height : auto; */
  width: 400px;
  height: 430px !important;
  overflow-y: auto !important;
}

.drop-down-site-owner .placeholder {
  color: rgb(0, 0, 0);
  opacity: 0.5;
  cursor: pointer;
}

.ul {
  list-style: none;
  padding: 10px 10px 0px 10px;
  /* background: #6c6c6b2b; */
}

.ul li {
  cursor: pointer;
  font-size: 15px;
  margin-top: 8px;
  display: flex;
  flex-direction: column-reverse;
  /* justify-content: space-between; */
}

.ul li.u2NtK.active {
  color: #6145e3 !important;
}

.ul li span:nth-child(1) {
  /* width: 60%; */
}

.ul li span:nth-child(2) {
  /* width     : 40%; */
  /* text-align: right; */
  font-weight: 600;
}

.ul li:last-child {
  margin-bottom: 8px;
}

.ul li:nth-child(2) {
  margin-top: 8px;
}

.ul li:hover {
  color: #116dff;
  transition: 0.5s;
}

.child_wrapper._12Ay3 ._3RLzW {
  box-shadow: 15px 15px 20px 0 rgb(194 199 203 / 16%),
    -15px 0 20px 0 rgb(194 199 203 / 14%);
}

.q-row {
  padding: 0px 10px;
}

.q-row:hover {
  /* background: #e2e1e54d; */
}

.submit_btn .downbtn {
  background: transparent !important;
  border: none;
}

.answer-wrapper,
.last-question {
  margin-left: 30px;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 500ms;
  transition: height 500ms;
  margin-top: 30px;
}

.last-question.active {
  height: auto;
}

.last-question.active {
  margin-left: 20px;
}

.summary-text {
  font-weight: 600;
}

.heading {
  font-size: 20px;
  display: flex;
  align-items: center;
  /* font-weight: 600; */
}

.second-question.active {
  height: auto !important;
}

.answer-wrapper.active {
  /* height: 200px; */
  height: auto;
}

aside.diamond {
  background-color: #ebe7fc;
  /* width           : calc(100% - 100px); */
  padding: 10px;
  height: auto;
  border-radius: 50px 0 0 50px;
  display: flex;
  align-items: center;
  min-height: 48px;
}

.diamond .round {
  height: 40px;
  width: 40px;
  color: white;
  font-weight: 400;
  background: #846aff;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 10px 19px 0 rgb(132 106 255 / 52%);
}

.diamond.blocked {
  background-color: #ff98004f !important;
}

.round.blocked {
  background-color: #ff9800ad !important;
  box-shadow: 0 10px 19px 0 rgb(255 152 0 / 35%) !important;
}

.diamond .right {
  margin-left: 50px;
}

aside {
  margin: 10px 0;
  line-height: 34px;
  position: relative;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.section-with-graph {
  background: #ebe7fc;
  position: relative;
  margin-left: 44px;
}

.last-question .section-with-graph {
  height: 440px;
}

.section-with-graph aside.diamond {
  margin-left: -44px;
  margin-top: 0;
  margin-bottom: 0;
}

.section-with-graph aside.round {
  margin-top: -3px;
}

.recharts-surface {
  overflow: visible;
}

.main-content .title .heading .back-btn {
  font-size: 18px;
}

.main-content .why-card {
  min-height: 550px;
}

.input-group-prepend {
  min-width: 55px;
}

.custom-dropdown {
  width: 500px;
}

.custom-dropdown .dropdown-menu {
  width: 100%;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
