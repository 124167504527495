.main-content {
    .content {
        .form {
            .custom_row {
                margin: 10px 0;
            }

            .custom_column {
                display       : flex;
                flex-direction: column;

                lable {
                    font-weight: 600;
                }
            }
        }
    }
}