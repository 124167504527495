nav.sliderbar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    width: 280px;
    background: #7659ff url(../images/menu-bg.png) top left no-repeat;
    height: 100vh;
    border-radius: 0 10px 10px 0;
    display: block;

    .navbar-toggler {
        position: fixed;
        left: 0;
        top: 0;
        background: url(../images/toggler-bg.png) left top no-repeat;
        border: 0;
        padding: 18px 0 0 20px;
        margin: 0;
        width: 90px;
        height: 84px;
        border-radius:0;
        vertical-align: top;
        display: flex;
        flex-wrap: wrap;
        outline: 0;

        .navbar-toggler-icon {
            display: block;
            width: 30px;
            height: 17px;
            vertical-align: top;
            background: url(../images/toggler-icon.png) left top no-repeat;
        }

    }

    .navbar-brand {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 0;
        padding: 22px 0;
        justify-content: center;

        h3 {
            width: 100%;
            display: block;
            font-size: 20px;
            font-weight: 800;
            color: #fff;
            line-height: 1;
            text-align: center;
            margin: 12px 0 0 0;
            text-transform: uppercase;
        }

    }

    .collapse:not(.show) {
        display: block;
    }

    .navbar-collapse {
        display: block;
        overflow: auto;
        height: calc(100vh - 124px);

        ul {
            padding: 0 0 0 20px;
            margin: 0;
            list-style: none;
            display: block;

            li {
                display: block;
                margin: 0;

                a.nav-link {
                    display: block;
                    padding: 20px;
                    font-size: 15px;
                    font-weight: 500;
                    color: #ffffff;
                    line-height: 1;
                    border-radius: 30px 0px 0px 30px;

                    &.active {
                        background: #fff;
                        color: #000;
                    }

                    i {
                        width: 17px;
                        height: 17px;
                        margin-right: 20px;
                    }

                }

            }

        }

    }

}